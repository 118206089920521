/**
* GLOBAL STYLES
* Imported once at the top level
* Used everywhere
*/
@import "fonts";
@import "theme";

* {
  box-sizing: border-box;
}

p,
b,
span {
  font-family: $font-family;
  line-height: 20px;
  size: 16px;
}

h1,
h2,
h3,
h4,
h5 {
  font-family: $font-family-title;
}

svg,
img {
  // TODO: get rid of this once chakra is more integrated
  display: inline !important;
}

ul {
  list-style: none;
}

:root {
  --primary-foreground: #000;
  --primary-background: #fff;
  --background: #FFFefd;
  --background-back: #FFFefd;
  //--background: #ffffe9;
  --background-lightgrey: #dfdde3;
  --background-element: #1C00ff00;
  //--background-element: RGBA(213, 186, 219, 0.14);
  //--background-element: rgba(213, 187, 222, 0.01);
  //--background-element: #FFFefd;
  //--background-element-hover: rgba(399, 144, 177, 0.88);
  --background-element-hover: rgba(399, 144, 177, 0.88);
  //--background-main: rgb(138,245,105);
  //--background-main: linear-gradient(0deg, rgba(138,245,105,0.20012955182072825) 0%, rgba(0,215,255,0.10127801120448177) 11%, rgba(225,178,245,0.14329481792717091) 36%, rgba(255,254,253,0) 91%);
  //--background-main: #FFFefd url("../assets/bg.png") no-repeat;
  --background-logo: #{$primary};
  --secondary-button: #{$light-blue};
  --outline: #000;
  --outline-gray: #8c8c94;
  --option-hover: #{$primary-blue-light};

  --text: #000;
  --text-darker: #000;
  --text-lighter: #686868;
  --text-lightest: #a5a5a5;
  --text-title: #{text-black};
  --text-primary: #{$primary-blue};
  --text-selected: #{$primary-blue};
  --text-secondary-button: #{$primary-blue};
  --text-secondary-button-disabled: #{$indigo20T};
  --header-text: #1f2023;
  --sign-graph: #000;

  --shadow: 0px 2px 8px rgba(39, 14, 1, 0.1);
  --shadow-hover: 2px 2px 12px rgba(68, 64, 64, 0.2);
}


// Dark mode themes
body.dark {
  --primary-foreground: #fff;
  --primary-background: #000;
  --background: #1a202c;
  --background-back: #000;
  --background-lightgrey: #{$black80};
  --background-element: rgba(0, 0, 0, 0.56);
  --background-element-hover: rgba(399, 144, 177, 0.88);
  //--background-main: #000 url("../assets/bg.png") center/auto 60% no-repeat
  //  scroll, local;
  //--background-main: rgb(49,166,86);
  //--background-main: linear-gradient(0deg, rgba(49,166,86,0.4318102240896359) 0%, rgba(57,9,51,0.742734593837535) 52%, rgba(0,0,0,1) 91%);
  //--background-main: #000 url("../assets/bg.png") no-repeat;
  --background-logo: #000;
  --secondary-button: #{$dark-purple};
  --outline: #000;
  --option-hover: #{$primary-blue-dark};
  --outline-gray: #8c8c94;

  --text: #fff;
  --text-darker: #ffffe9;
  --text-lighter: #bbbbbb;
  --text-lightest: #888888;
  --text-title: #ffffe9;
  --text-primary: #{$primary};
  --text-selected: #{$primary};
  --text-secondary-button: #{$blue};
  --text-secondary-button-disabled: #{$blue40T};
  --header-text: #{$primary};
  --sign-graph: #{$primary-blue};

  --shadow: 0px 2px 8px rgba(39, 14, 1, 0.18);
  --shadow-hover: 2px 2px 12px rgba(68, 64, 64, 0.14);
}
