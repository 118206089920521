@import "../styles/theme";
@import "../styles/viewports";

header.top {
  background-color: #1C00ff00;
  height: 88px;
  padding-left: 46px;
  padding-right: 46px;
  display: flex;
  justify-content: center;
  align-items: center;
  @include breakpoint(sm) {
    padding-left: 30px;
    padding-right: 46px;
  }

  h1 {
    width: 20%;
    margin: 0;
    margin-right: auto;

    .logo {
      background-color: #1C00ff00;
      border-radius: 10px;
      display: inline-block;
      margin-right: auto;
      width: 32px;
      height: 32px;
      @include breakpoint(md) {
        margin-right: auto;
      }
      &:hover {
        cursor: pointer;
      }
    }
  }

  ul.nav {
    font-family: $font-family;
    list-style-type: none;
    padding: 0;
    margin: 0;

    & > li {
      display: inline;
      margin-right: 40px;
      @include breakpoint(lg) {
        margin-right: 32px;
      }
      @include breakpoint(md) {
        margin-right: 24px;
      }
      @include breakpoint(sm) {
        margin-right: 16px;
      }
      @include breakpoint(xs) {
        margin-right: 12px;
      }
      &:last-child {
        margin-right: 0;
      }
      a {
        color: var(--header-text);
        text-decoration: none;
        font-style: normal;
        font-weight: $normal;
        font-size: 19px;
        @include breakpoint(lg) {
          font-size: 19px;
        }
        @include breakpoint(md) {
          font-size: 19px;
        }
        @include breakpoint(sm) {
          font-size: 19px;
        }
        &:hover {
          opacity: 1;
        }

        &.active {
          opacity: 1;
          // color: var(--header-text);
          font-weight: $bold;
        }
      }
    }
  }
  .walletStatus {
    margin-left: auto;
    width: 20%;

    .connection {
      // padding: 8px;
      // border: 1px solid;
      // border-radius: 10px;
      &.success .indicator {
        filter: invert(1);
      }
    }
    img.logo {
      height: 30px;
    }
  }
}
