@import "../styles/theme";
@import "../styles/viewports";

div.swapPage {
  min-height: 100vh;
  background: var(--background);
  color: var(--text);
  font-size: 16px;
  font-family: $font-family;
  padding-bottom: 40px;
  box-sizing: border-box;

  h3.swapTitle {
    font-weight: 500;
    font-size: 24px;
    line-height: 30px;
  }

  button:hover {
    cursor: pointer;
  }

  .swapForm {
    width: 100%;
    background: var(--background-element);
    border: 1px solid var(--outline);
    border-radius: 10px;
    padding: 24px;
    .row {
      display: flex;
      justify-content: space-between;
      margin-top: 8px;
      width: 100%;
      align-items: baseline;
    }
    .balanceContainer {
      span,
      a {
        font-size: 12px;
      }
      a {
        font-weight: 500;
        color: var(--text-selected);
        &:hover {
          cursor: pointer;
        }
      }
    }
  }

  .content {
    width: 550px;
    margin: 0 auto;
    padding: 24px 32px;
    padding-bottom: 40px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
    @include breakpoint(sm) {
      width: 100%;
    }
  }

  button.exchange {
    border: none;
    background: $primary;
    height: 24px;
    // margin-left: 4px;
    padding: 0;
    border-radius: 4px;

    &:hover {
      transform: none;
    }
  }

  .exchangeWarning {
    color: $white;
    background-color: $text-red;
    padding: 8px;
    width: 100%;
    margin-top: 32px;
    display: flex;
    justify-content: center;
  }

  .infoSection {
    width: 100%;
    display: flex;
    margin-top: 32px;
    .title {
      cursor: pointer;
      svg {
        margin-left: 10px;
        transform: norotate(0deg);
        &.upsideDown {
          transform: rotate(-180deg);
        }
      }
    }
  }

  .advancedOptions {
    width: 100%;
    margin-bottom: 32px;
    font-family: $font-family;

    .tableContainer {
      display: none;
      &.show {
        display: block;
      }
    }

    .table {
      width: 100%;
      background: var(--background-element);
      margin-top: 16px;
      border: 1px solid var(--outline);
      border-radius: 10px;
      padding: 16px 24px;

      display: flex;
      flex-flow: column wrap;
      justify-content: flex-start;
      align-items: flex-start;
      .parameter {
        margin: 2px 0;
      }
      .parameter .options {
        margin-top: 10px;
      }
    }
  }

  .error {
    width: 100%;
    color: $error;
    font-weight: $normal;
    text-align: center;
    margin-top: 8px;
    display: none;
  }

  .info {
    width: 100%;
    text-align: center;
    margin-top: 8px;
    display: none;
  }
  .showInfo,
  .showError {
    display: block;
  }

  .pendingSwaps {
    width: 100%;
    > .pendingSwapItem {
      width: 100%;
      border: 1px solid $primary-blue;
      border-radius: 10px;
      background: var(--primary-background);
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 16px 24px;
      .swapDetails {
        color: $primary-blue;
        font-weight: 700;
        font-size: 16px;
      }
      .swapTimeContainer {
        display: flex;
        align-items: center;
        > svg {
          margin-right: 10px;
        }
        span.time {
          font-size: 16px;
        }
      }
    }
  }
}
